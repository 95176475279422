import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: Montserrat;
    src: url(/assets/Montserrat-Regular.otf);
  }
  
  @font-face {
    font-family: PlayfairDisplay;
    src: url(/assets/PlayfairDisplay-Regular.otf);
  }

  body {
    font-family: Montserrat;
    background: url(/assets/background2.jpg);
    background-color: #000;
    background-size: auto;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.5); /* White overlay with 50% opacity */
    z-index: 0; /* Place it above the background image */
  }

  h1.ant-typography {
    font-size: 40px;
  }

  h2.ant-typography {
    font-size: 30px;
  }

  h3.ant-typography {
    font-size: 25px;
  }
`
